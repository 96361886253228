import UpsellTile from "./UpsellTile";
import React, { useState } from "react";
import ProductOffer from "../../store/product-offer/model";
import { useDispatch, useSelector } from "react-redux";
import BundleModal from "../bundleLanding/BundleModal";
import { addProductToCart } from "../../store/cart/actions";
import cartService from "../../services/cart";

export type ProductOfferUpsellTileProps = {
  productOffer: ProductOffer;
};

const ProductOfferUpsellTile = (props: ProductOfferUpsellTileProps) => {
  const [isBundleModelOpen, setIsBundleModelOpen] = useState(false);
  const dispatch = useDispatch();
  const productOffer = props.productOffer;

  const productBundle = useSelector((state) =>
    productOffer.contentfulProductBundle(state),
  );
  const price = useSelector((state) => productOffer.initialAmount(state));
  const strikeThroughPrice = useSelector((state) =>
    productOffer.futureAmount(state),
  );

  const onCtaClick = () => {
    dispatch(
      addProductToCart({
        productOfferId: productOffer.id,
      }),
    );
    cartService.openCart();
  };

  if (!productBundle) {
    return null;
  }

  return (
    <>
      <UpsellTile
        title={productOffer.name}
        subheading={productBundle.shopDescription}
        price={price}
        strikeThroughPrice={strikeThroughPrice}
        image={productBundle.shopImages[0].gatsbyImageData}
        onCtaClick={onCtaClick}
        onMoreInfoClicked={() => {
          setIsBundleModelOpen(true);
        }}
        savingsText={productBundle.flag}
      />
      <BundleModal
        isOpen={isBundleModelOpen}
        onRequestClose={() => {
          setIsBundleModelOpen(false);
        }}
        bundle={productBundle}
      />
    </>
  );
};

export default ProductOfferUpsellTile;
