import useProductOfferPdp from "../../../hooks/launchdarkly/useProductOfferPdp";
import useProductOfferUpsells from "../../../hooks/launchdarkly/useProductOfferUpsells";
import { useSelector } from "react-redux";
import { productOfferForSlug } from "../../../store/product-offer/selectors";
import ProductOfferUpsellTile from "../ProductOfferUpsellTile";
import styled from "styled-components";
import intl from "../../../services/intl";
import { responsive } from "../../../utils/style";

const BundleAndSave = styled.span`
  margin-bottom: var(--spacing-1);
  margin-top: var(--spacing-2);
  font-weight: 500;

  ${responsive.lg`
    margin-top: 40px;
   `}
`;

type Props = {
  productSku: string;
};
const ProductOfferUpsell = ({ productSku }: Props) => {
  const productOfferPdpFlag = useProductOfferPdp();
  const productOfferUpsells = useProductOfferUpsells(productSku);

  const upsellSlug = productOfferUpsells[0];
  const productOffer = useSelector((state) =>
    productOfferForSlug(state, upsellSlug),
  );

  if (productOfferPdpFlag === "control" || !upsellSlug || !productOffer) {
    return null;
  }

  return (
    <>
      <BundleAndSave className={"typography-lead2"}>
        {intl.t("upsell-tile.bundle-and-save", "Bundle and Save")}
      </BundleAndSave>

      <ProductOfferUpsellTile productOffer={productOffer} />
    </>
  );
};

export default ProductOfferUpsell;
