import React from "react";
import intl from "../../services/intl";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import styled from "styled-components";
import { RBadge, CaptionTitle, RPrice } from "@ritual/essentials-for-react";
import currency from "../Currency";
import { Font, responsive } from "../../utils/style";

export type UpsellTileProps = {
  title: string;
  subheading: string;
  price: number;
  strikeThroughPrice: number;
  image: IGatsbyImageData;
  onCtaClick: () => void;
  savingsText?: string;
  onMoreInfoClicked: () => void;
};

const UnstyledButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: left;
`;

const UnstyledButtonImageWrapper = styled(UnstyledButton).attrs({
  tabIndex: -1,
  "aria-hidden": "true",
})``;

const UpsellTileContainer = styled.div`
  padding: var(--spacing-1);
  background: #f8f7f9;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;

  ${responsive.sm`
    max-width: 280px;
  `}
  ${responsive.md`
    max-width: 370px;
  `}
  .image-wrapper {
    width: 80px;
    height: 96px;
    display: flex;
    background-color: #f2f1f6;
  }

  .gatsby-image-wrapper {
    width: 80px;
  }

  .content-wrapper {
    padding-left: var(--spacing-0_75);
    width: 100%;

    .title {
      color: var(--indigo-blue);
    }

    caption-title {
      font-weight: 300;
      font-family: 'LL Circular';
    }

  }


  .flag {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 1;

    .typography-caption {
      display: inline-block;
      padding: var(--spacing-0_25) var(--spacing-0_5);
      border-radius: var(--spacing-0_25);
      color: var(--indigo-blue);
      background: var(--yellow-ochre-60);
      font-weight: 300;
      font-family: 'LL Circular';
    }
  }

  .bottom-container {
    padding-top: var(--spacing-1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price-container {
      display: flex;
      align-items: baseline;

      r-price {
        &:last-child {
          padding-left: var(--spacing-0_25);
        }
      }
    }

    .cta-container {
      button {
        background: #fff;
        border: 0;
        padding: var(--spacing-0_75) var(--spacing-1);
        color: var(--indigo-blue);
        font-family: var(--font-circular);
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1rem;
        border-radius: 25px;

        &:hover {
          background: var(--indigo-blue);
          color: #fff;
        }
      }
    }
`;

const UpsellTile = (props: UpsellTileProps) => {
  const ctaText = intl.t("upsell-tile.cta");

  return (
    <UpsellTileContainer>
      {props.savingsText && (
        <div className={"flag"}>
          <div className="typography-caption">{props.savingsText}</div>
        </div>
      )}
      <div className="image-wrapper">
        <UnstyledButtonImageWrapper onClick={props.onMoreInfoClicked}>
          <GatsbyImage image={props.image} alt={props.title} />
        </UnstyledButtonImageWrapper>
      </div>
      <div className={"content-wrapper"}>
        <UnstyledButton onClick={props.onMoreInfoClicked}>
          <div className={"title typography-label3"}>{props.title}</div>
          <CaptionTitle className={"typography-caption"}>
            {props.subheading}
          </CaptionTitle>
        </UnstyledButton>
        <div className={"bottom-container"}>
          <div className={"price-container"}>
            <RPrice className={"typography-label3"}>
              {currency({ value: props.price / 100 })}
            </RPrice>
            <RPrice className={"typography-label3 strikethrough"}>
              {currency({ value: props.strikeThroughPrice / 100 })}
            </RPrice>
          </div>
          <div className="cta-container">
            <button
              type={"button"}
              aria-label={ctaText}
              onClick={props.onCtaClick}
            >
              {ctaText}
            </button>
          </div>
        </div>
      </div>
    </UpsellTileContainer>
  );
};

export default UpsellTile;
